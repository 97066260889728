import React from 'react';
import BlogClassicData from '../../data/blog/BlogList.json';
import AuthorListPost from '../../components/blog/AuthorListPost';
import { slugify} from "../../utils";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../elements/breadcrumb/BreadcrumbOne";
import HeaderBlog from '../../common/header/HeaderBlog';
import FooterFour from '../../common/footer/FooterFour';

// const Author = ({match: {params: {slug}}}) => {
const Author = ({params}) => {
    const data = BlogClassicData.map(blog => {
        return {
            ...blog,
            author: blog.author.filter(cat => cat === params.author)
        }
    }).filter(blog => blog.author.length > 0);
    if (data[0]) {
        const categoryTitle = data[0].author[0];
        return (
            <>
                <SEO title="Blog Post By Vikram Singh" />

                {/* <Layout> */}
                <div>
                    <HeaderBlog btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />
                    <BreadcrumbOne 
                        title={categoryTitle}
                        rootUrl="/"
                        parentUrl="Home"
                        currentUrl="Vikram Singh"
                    />

                    <div className="main-content">
                        {/* Start Blog Area  */}
                        <div className="rn-blog-tag-area rn-section-gap">
                            <div className="container">
                                <AuthorListPost data={data} Column="col-lg-4" />
                            </div>
                        </div>
                        {/* End Blog Area  */}
                    </div>
                </div>
                {/* </Layout> */}
                <FooterFour />
            </>
        )
    } else {
        return (
            <> </>
        )
    }
}

export default Author;